<template>
  <div>
    <el-dialog title="走访记录详情" class="cus_dialog" :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false" :destroy-on-close="true" @close="closeDialog" width="800px">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff"
          ><span style="font-weight: 700">{{ EditItem.username }}</span> 走访记录详情</span
        >
      </div>
      <div>
        <div v-if="EditItem" class="cellGroupBarTitle flexStart" style="margin-top: 10px">
          <span></span>
          <span style="font-weight: 400">走访详情</span>
        </div>
        <table class="dttable" v-if="EditItem">
          <tr>
            <td class="tdheader">走访教师</td>
            <td style="width: 30%">{{ EditItem.username }}</td>
            <td class="tdheader">走访日期</td>
            <td style="width: 30%">{{ EditItem.visit_date }}</td>
          </tr>

          <tr>
            <td class="tdheader">走访学生</td>
            <td colspan="3">
              <table class="dttable">
                <tr v-for="(stu, i) in EditItem.stu_list" :key="i">
                  <td>{{ stu.stu_name }}</td>
                  <td>{{ stu.stu_code }}</td>
                  <td>{{ stu.class_name }}</td>
                  <td>{{ stu.firm_name }}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td class="tdheader">企业名称</td>
            <td>
              {{ EditItem.enter_name }}
            </td>
            <td class="tdheader">岗位</td>
            <td>
              {{ EditItem.job }}
            </td>
          </tr>
          <tr>
            <td class="tdheader">走访地址</td>
            <td colspan="3" style="word-break: break-all">
              {{ EditItem.visit_address }}
            </td>
          </tr>
          <tr>
            <td class="tdheader">接待人员及职位</td>
            <td colspan="3" style="word-break: break-all">
              {{ EditItem.jdrjzw }}
            </td>
          </tr>
          <tr>
            <td class="tdheader">走访小结</td>
            <td colspan="3" style="word-break: break-all">
              {{ EditItem.visit_summary }}
            </td>
          </tr>

          <tr>
            <td class="tdheader">走访表拍照</td>
            <td colspan="3">
              <div v-if="!EditItem.fieldname">-</div>
              <div style="display: inline-block; margin-right: 10px; vertical-align: top; position: relative" v-for="(img, idx) in EditItem.fieldname" :key="idx">
                <el-image style="width: 100px; height: 100px" :src="img.fileUrl || img.src" :preview-src-list="[img.fileUrl || img.src]" fit="cover" :z-index="3000"></el-image>
<!-- 
                <div class="imgremove" v-if="EditItem.is_draft == 1" @click="removeImg(idx)"><i class="el-icon-remove"></i></div> -->
              </div>

              <!-- <div style="display: inline-block; vertical-align: top" v-if="EditItem.is_draft == 1">
                <el-upload style="display: inline-block; margin-right: 20px" action="" :http-request="uploadOss" :on-success="uploadFiles" :show-file-list="false" name="image">
                  <div class="imgaddbtn">＋</div>
                </el-upload>
              </div> -->
            </td>
          </tr>
        </table>

        <div v-if="xunfangsp && EditItem.audit_status > 0" class="cellGroupBarTitle flexStart" style="margin-top: 20px">
          <span></span>
          <span style="font-weight: 400">审批详情</span>
        </div>
        <table class="dttable" v-if="xunfangsp && EditItem.audit_status > 0">
          <tr v-if="xunfangsp && EditItem.audit_status > 0">
            <td class="tdheader">审批人</td>
            <td class="" colspan="3">
              <div class="flexStart">
                {{ EditItem.audit_username }}
              </div>
            </td>

            <!-- <td class="tdheader">是否外勤</td>
						<td>
							{{ EditItem.fieldwork == 1 ? '是' : '否' }}
						</td> -->
          </tr>

          <tr v-if="xunfangsp && EditItem.audit_status > 0">
            <td class="tdheader">审批结果</td>
            <td colspan="3">
              <el-tag type="success" v-if="EditItem.audit_status == 1" size="small">审批通过</el-tag>
              <el-tag type="danger" v-if="EditItem.audit_status == 2" size="small">审批拒绝</el-tag>
              <!-- <div>
								<el-image style="width: 200px; height: 100px" :src="EditItem.sign_url"
									:preview-src-list="[EditItem.sign_url]" img="cover" :z-index="3000"></el-image>
							</div> -->
            </td>
          </tr>
          <tr v-if="xunfangsp && EditItem.audit_status > 0">
            <td class="tdheader">审批评语</td>
            <td colspan="3">
              {{ EditItem.audit_remark }}
            </td>
          </tr>
          <tr v-if="xunfangsp && EditItem.audit_status > 0">
            <td class="tdheader">审批时间</td>
            <td colspan="3">
              <el-tag type="info" v-if="EditItem.audit_date" size="small">{{ EditItem.audit_date }}</el-tag>
            </td>
          </tr>
          <tr v-if="xunfangsp && EditItem.audit_status > 0">
            <td class="tdheader">审批人签名</td>
            <td colspan="3">
              <el-image v-if="EditItem.sign_url" :src="EditItem.sign_url" :preview-src-list="[EditItem.sign_url]" fit="contain" style="height: 50px; margin-left: 10px"></el-image>
            </td>
          </tr>
        </table>

        <!-- <el-row v-if="!EditItem.is_draft&&EditItem.audit_status !=1">
					<el-col :span="16" style="text-align: right">
						<el-input v-model.trim="spword" style="width: 100%" size="small" placeholder="审核拒绝时请填写拒绝原因">
						</el-input>
					</el-col>
					<el-col :span="8" style="text-align: left;padding-left: 10px;">
						<el-button size="small" type="warning" @click="submitForm(2)">审批拒绝</el-button>
						<el-button size="small" type="primary" @click="submitForm(1)">审批通过</el-button>
					</el-col>
				</el-row> -->

        <el-row v-if="EditItem && EditItem.is_draft">
          <el-col :span="22" style="text-align: right; padding-left: 10px">
            <el-button size="small" icon="el-icon-edit" type="" @click="submitForm(1)">保存草稿</el-button>

            <el-button size="small" icon="el-icon-edit" type="primary" @click="submitForm(0)"> {{ xunfangsp ? "提交审核" : "提交" }} </el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["id", "xunfangsp"],
  data() {
    return {
      dialogshow: true,
      userinfo: null,
      EditItem: [],
      process_code: "leave_1",
      needChoose: false, //是否需要选择下级
      choosedUser: [], //已选择的用户.
      spword: "",
      listForm: [],
      listState: [],
      objForm: {},
      objState: {},
    };
  },
  mounted() {
    this.getTeacheInfo();
    this.getAllPickerVal();
  },
  methods: {
    getTeacheInfo() {
      this.$http.post("/api/get_teacher_info").then((res) => {
        this.userinfo = res.data;
      });
    },
    getData() {
      this.$http.post("/api/teacher_visit_info", { id: this.id }).then((res) => {
        try {
          if (res.data.zf_url) {
            res.data.fieldname = [
              {
                fileName: 1,
                fileUrl: res.data.zf_url,
              },
            ];
          }
        } catch (error) {}
        res.data.stu_list = JSON.parse(res.data.stu_list);
        res.data.visit_form += "";
        res.data.situation = parseInt(res.data.situation);
        res.data.visit_form_label = this.objForm[res.data.visit_form];
        res.data.situation_label = this.objState[res.data.situation];
        res.data.situation_label = this.listState.filter((item) => res.data.situation == item.param_value).map((m) => m.param_desc)[0];
        res.data.visit_date = res.data.visit_date.substring(0, 10);

        this.EditItem = res.data;
      });
    },
    getAllPickerVal() {
      //走访形式&&走访情况
      this.$http.post("/api/sys_param_list", { param_type: "'VISIT_FORM','VISIT_SITUATION'" }).then((res) => {
        let listForm = [],
          listState = [],
          objForm = {},
          objState = {};
        res.data.map((item, index, arr) => {
          if (item.param_type == "VISIT_FORM") {
            listForm.push(item);
            objForm[item.param_value] = item.param_desc;
          }
          if (item.param_type == "VISIT_SITUATION") {
            listState.push(item);
            objState[item.param_value] = item.param_desc;
          }
        });

        this.listForm = listForm;
        this.listState = listState;
        this.objForm = objForm;
        this.objState = objState;
        this.getData();
      });
    },
    closeDialog() {
      this.$$parent(this, "dialogshow", false);
    },
    uploadFiles(e) {
      this.EditItem.fieldname.push(e);
    },

    preCheckData(e) {
      let DataInfo = this.EditItem;

      if (DataInfo.visit_summary.length < 100) {
        this.$message({
          type: "error",
          message: "走访小结必须大于100字",
        });
        return false;
      }
    },
    removeImg(idx) {
      this.EditItem.fieldname.splice(idx, 1);
    },

    isChooseuser(e) {
      //设置是否需要选人
      this.needChoose = e;
    },
    chooseUser(e) {
      //设置选择的人

      this.choosedUser = e;
    },
    submitForm(st) {
      this.$confirm("确定" + (st ? "保存草稿" : "提交审批") + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let data = JSON.parse(JSON.stringify(this.EditItem));
        data.fieldname = JSON.stringify(data.fieldname);
        data.stu_list = JSON.stringify(data.stu_list);
        data.is_draft = st;

        this.$http.post("/api/teacher_visit_edit", data).then((res) => {
          this.$message({
            type: "success",
            message: st ? "保存草稿成功" : "提交成功",
          });
          this.$$parent(this, "getList");
          this.$$parent(this, "dialogshow", false);
        });
      });
    },
  },
};
</script>

<style scoped>
.imgaddbtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dotted #ccc;
  font-size: 30px;
  cursor: pointer;
}

.imgremove {
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;

  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -5px;
  color: #ccc;
}

.imgremove:hover {
  color: orange;
}

.cellGroupBarTitle {
  margin-bottom: 10px;
}
.cellGroupBarTitle span:nth-of-type(2) {
  color: #2469f3;
}
</style>
